import {
  collection,
  addDoc,
  updateDoc,
  onSnapshot,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
  query, where,
} from "firebase/firestore";

import { dbFirebase } from "./config";

const collectionName = "users";

export const saveDocFirestore = (collectionName = null, id = null, document) => {
  console.log('saveDocFirestore...', collectionName, id, { ...document })
  if (id) {
    const docRefF = doc(dbFirebase, collectionName, id);
    return setDoc(docRefF, { ...document }, { merge: true });
  }
  return addDoc(collection(dbFirebase, collectionName), { ...document });

}

export const getDocFirestore = async (collectionName = null, id = null,) => {
  if (collectionName && id) {
    const docRef = doc(dbFirebase, collectionName, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    }
  }
}

export const getCollection = async (collectionName = null, condition = null) => {
  if (collectionName && condition) {
    const users = [];
    const CollectionRef = collection(dbFirebase, collectionName);
    const q = query(CollectionRef, where(condition.field, condition.operator, condition.value));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      users.push(doc.data())
    });
    console.log(users);
    return users;
  }
}
