// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  kanban: getIcon('ic_kanban')
};

// visitante
export const SidebarConfigLevel5 = [
  {
    subheader: 'general',
    items: [
      {
        title: 'Inicio',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.chat
      }
    ]
  },
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // MANAGEMENT : USER
      {
        title: 'Mi cuenta',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'account', path: PATH_DASHBOARD.user.account }
        ]
      }
    ]
  }
];

// consultante
export const SidebarConfigLevel4 = [
  {
    subheader: 'general',
    items: [
      {
        title: 'Inicio',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.chat
      }
    ]
  },
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // MANAGEMENT : USER
      {
        title: 'Mi cuenta',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'account', path: PATH_DASHBOARD.user.account }
        ]
      },

      // MANAGEMENT : E-COMMERCE
      {
        title: 'Sesiones',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.cart,
        children: [
          { title: 'Sesión', path: PATH_DASHBOARD.eCommerce.productById },
          { title: 'Lista', path: PATH_DASHBOARD.eCommerce.list },
          { title: 'Crear Nueva', path: PATH_DASHBOARD.eCommerce.newProduct },
          { title: 'PDF', path: PATH_DASHBOARD.eCommerce.invoice }
        ]
      },
    ]
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'app',
    items: [
      { title: 'Calendario', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
      { title: 'Tareas', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban }
    ]
  }
];

// terapeuta
export const SidebarConfigLevel3 = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'Mi Reporte de Pacientes',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      },
      { title: 'Reporte de paciente Goblal', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // MANAGEMENT : USER
      {
        title: 'Mi cuenta',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'account', path: PATH_DASHBOARD.user.account }
        ]
      },
      {
        title: 'Solicitudes',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'Nuevas Solicitudes', path: PATH_DASHBOARD.request.list },
          { title: 'Editar', path: PATH_DASHBOARD.request.editById },
        ]
      },

      // MANAGEMENT : E-COMMERCE
      {
        title: 'Sesiones',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.cart,
        children: [
          { title: 'Sesión', path: PATH_DASHBOARD.eCommerce.productById },
          { title: 'Lista', path: PATH_DASHBOARD.eCommerce.list },
          { title: 'Crear Nueva', path: PATH_DASHBOARD.eCommerce.newProduct },
          { title: 'Editar', path: PATH_DASHBOARD.eCommerce.editById },
          { title: 'PDF', path: PATH_DASHBOARD.eCommerce.invoice }
        ]
      },
    ]
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'app',
    items: [
      { title: 'Calendario', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
      { title: 'Tareas', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban }
    ]
  }
];

// admin
export const SidebarConfigLevel2 = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'Mi Reporte de Pacientes',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      },
      { title: 'Reporte de paciente Goblal', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // MANAGEMENT : USER
      {
        title: 'Mi cuenta',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'account', path: PATH_DASHBOARD.user.account }
        ]
      },
      {
        title: 'Consultutantes',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'Crear Nuevo', path: PATH_DASHBOARD.user.newUser },
          { title: 'Lista', path: PATH_DASHBOARD.user.list },
          { title: 'Editar', path: PATH_DASHBOARD.user.editById },
        ]
      },

      // MANAGEMENT : E-COMMERCE
      {
        title: 'Sesiones',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.cart,
        children: [
          { title: 'Sesión', path: PATH_DASHBOARD.eCommerce.productById },
          { title: 'Lista', path: PATH_DASHBOARD.eCommerce.list },
          { title: 'Crear Nueva', path: PATH_DASHBOARD.eCommerce.newProduct },
          { title: 'Editar', path: PATH_DASHBOARD.eCommerce.editById },
          { title: 'PDF', path: PATH_DASHBOARD.eCommerce.invoice }
        ]
      },
    ]
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'app',
    items: [
      { title: 'Calendario', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
      { title: 'Tareas', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban }
    ]
  }
];

// super
export const SidebarConfigLevel1 = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'Mi Reporte de Pacientes',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      },
      { title: 'Reporte de paciente Goblal', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // MANAGEMENT : USER
      {
        title: 'Mi cuenta',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'account', path: PATH_DASHBOARD.user.account }
        ]
      },
      {
        title: 'Consultutantes',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'Crear Nuevo', path: PATH_DASHBOARD.user.newUser },
          { title: 'Lista', path: PATH_DASHBOARD.user.list },
          { title: 'Editar', path: PATH_DASHBOARD.user.editById },
        ]
      },

      // MANAGEMENT : E-COMMERCE
      {
        title: 'Sesiones',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.cart,
        children: [
          { title: 'Sesión', path: PATH_DASHBOARD.eCommerce.productById },
          { title: 'Lista', path: PATH_DASHBOARD.eCommerce.list },
          { title: 'Crear Nueva', path: PATH_DASHBOARD.eCommerce.newProduct },
          { title: 'Editar', path: PATH_DASHBOARD.eCommerce.editById },
          { title: 'PDF', path: PATH_DASHBOARD.eCommerce.invoice }
        ]
      },
    ]
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'app',
    items: [
      { title: 'Calendario', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
      { title: 'Tareas', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban }
    ]
  }
];
