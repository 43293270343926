import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

import { firebaseConfig } from '../../config';

const app = initializeApp(firebaseConfig);

export const authFirebase = getAuth(app);

export const dbFirebase = getFirestore();
