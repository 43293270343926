import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// ----------------------------
import {
  doc,
  getDoc,
} from "firebase/firestore";

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  sendPasswordResetEmail
} from "firebase/auth";

import { authFirebase, dbFirebase } from "../_apis_/firestore/config";
import { saveDocFirestore } from "../_apis_/firestore/api";
import { PATH_DASHBOARD } from '../routes/paths';
// ----------------------------------------------------------------------

const ADMIN_EMAILS = ['demo@zionsdo.com'];

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const reducer = (state, action) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  loginWithGoogle: () => Promise.resolve(),
  loginWithFaceBook: () => Promise.resolve(),
  loginWithTwitter: () => Promise.resolve(),
  logout: () => Promise.resolve()
});


const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(
    () =>
      onAuthStateChanged(authFirebase, async (user) => {
        if (user) {
          const { reloadUserInfo } = user;
          // const currentUser = proactiveRefresh.user;

          const objU = {
            "id": user.uid,
            "email": user.email,
            "emailVerified": user.emailVerified,
            "displayName": user.displayName,
            "photoURL": user.photoURL,
            "createdAt": reloadUserInfo?.createdAt || '',
            "lastLoginAt": reloadUserInfo?.lastLoginAt || '',
            "levelAccess": ADMIN_EMAILS.includes(auth.email) ? 1 : 5,
            "isPending": false,
            "descPending": '',
          }
          const docRef = doc(dbFirebase, 'users', user.uid)
          const docSnap = await getDoc(docRef)
          if (docSnap.exists()) {
            const today = new Date();
            let DataUser = docSnap.data();
            DataUser = { ...DataUser, "lastLoginAt": today.toISOString(), };
            user = DataUser
            console.log('updating lastLoginAt...', user);
            setProfile(user);
            await saveDocFirestore('users', user.id, user);
            dispatch({
              type: 'INITIALISE',
              payload: { isAuthenticated: true, user }
            });
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            setProfile(objU);
            await saveDocFirestore('users', user.uid, objU);
            dispatch({
              type: 'INITIALISE',
              payload: { isAuthenticated: true, user }
            });
          }
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: false, user: null }
          });
        }
      }),
    [dispatch]
  );

  const login = (email, password) => signInWithEmailAndPassword(authFirebase, email, password);

  const loginWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(authFirebase, provider);
  };

  const loginWithFaceBook = () => {
    const provider = new FacebookAuthProvider();
    return signInWithPopup(authFirebase, provider);
  };

  const loginWithTwitter = () => {
    const provider = new TwitterAuthProvider();
    return signInWithPopup(authFirebase, provider);
  };

  const register = (email, password, firstName, lastName) =>
    createUserWithEmailAndPassword(authFirebase, email, password)
      .then((res) => {
        dbFirebase.collection('users')
          .doc(res.user.uid)
          .set({
            uid: res.user.uid,
            email,
            displayName: `${firstName} ${lastName}`
          });
      });

  const logout = async () => {
    await signOut(authFirebase);
  };

  const resetPassword = async (email) => {
    await sendPasswordResetEmail(authFirebase, email);
  };


  const auth = { ...state.user };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: profile,
        login,
        register,
        loginWithGoogle,
        loginWithFaceBook,
        loginWithTwitter,
        logout,
        resetPassword,
        setProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node
};

export { AuthContext, AuthProvider };
